import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import ConfirmationModal from "components/ConfirmationModal";
import PageLayout from "components/PageLayout";
import Button from "components/button/Button";
import { primary, white } from "config/colors";
import { attemptAssessmentUrl } from "config/urls";
import { useStudentAssessments } from "hooks/students";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Assessment } from "types/assessments";

const StudentAssessmentsView = () => {
  const { isLoading, isFetching, data, error, refetch } =
    useStudentAssessments();
  const [selectedAssessment, setSelectedAssessment] =
    useState<Assessment | null>();

  const history = useHistory();

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <Grid container spacing={2}>
        {data?.data?.map((assessment) => {
          return (
            <Grid item xs={6} sm={4} md={3}>
              <Card
                sx={{
                  boxShadow: "0 3px 6px rgb(0 0 0 / 12%)",
                }}
              >
                <CardContent sx={{ padding: 0 }}>
                  <div
                    style={{
                      background: primary,
                      padding: 16,
                      paddingTop: 48,
                      paddingBottom: 48,
                      color: white,
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        marginBottom: 0,
                        textAlign: "center",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {assessment.name}
                    </Typography>
                  </div>

                  <div style={{ padding: 16, paddingBottom: 0 }}>
                    <Typography
                      variant="body1"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {assessment.description || "-"}
                    </Typography>

                    <Typography variant="body1" style={{ marginTop: 8 }}>
                      <b>Duration:</b> {assessment.time_in_mins} minutes
                    </Typography>

                    <Typography variant="body1" style={{ marginTop: 8 }}>
                      <b>Score: </b>
                      <span>
                        {assessment.attempt
                          ? `${assessment.attempt.score} (${parseInt(
                              Number(
                                (assessment.attempt.score /
                                  (assessment.total_points || 1)) *
                                  100
                              ).toString()
                            )}%)`
                          : "-"}
                      </span>
                    </Typography>

                    <Box display="flex" justifyContent="center">
                      <Button
                        variant="contained"
                        disabled={Boolean(assessment.attempt)}
                        style={{ marginTop: 32 }}
                        onClick={() => {
                          setSelectedAssessment(assessment);
                        }}
                      >
                        {assessment.attempt
                          ? "ALREADY TAKEN"
                          : "TAKE ASSESSMENT"}
                      </Button>
                    </Box>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <ConfirmationModal
        onCancel={() => setSelectedAssessment(null)}
        onConfirm={async () => {
          history.push(attemptAssessmentUrl(selectedAssessment!.id));
        }}
        title="TAKE ASSESSMENT"
        prompt={
          <p>
            Are you sure you want to take this timed assessment?
            <br />
            <br />
            You can do this only once, so make sure you have a stable internet
            connection
          </p>
        }
        open={Boolean(selectedAssessment)}
      />
    </PageLayout>
  );
};

export default StudentAssessmentsView;
