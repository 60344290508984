import PageLayout from "components/PageLayout";
import { useCategories } from "hooks/categories";
import { useStudentGroups } from "hooks/students";
import { Assessment } from "types/assessments";

import TextField from "components/Form/TextField";
import { Box, MenuItem, Select } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "components/button/Button";

interface IAssementFormProps {
  initialAssessment?: Assessment;
  inEditMode?: boolean;
  onSubmit: (assesement: Partial<Assessment>) => Promise<void | any>;
}

const validator = Yup.object().shape({
  name: Yup.string()
    .required("Enter assessment name")
    .min(3, "Three ore more characters allowed"),
  description: Yup.string(),
  category_id: Yup.number().required("Please select subject"),
  group_id: Yup.number().required("Please select group"),
  time_in_mins: Yup.number().required(
    "Enter total number of allowed minutes for the assessment"
  ),
});

const AssessmentForm = ({
  initialAssessment,
  onSubmit,
}: IAssementFormProps) => {
  const {
    isLoading: isGroupsLoading,
    isFetching: isGroupsFetching,
    error: groupsError,
    data: groups,
    refetch: refetchGroups,
  } = useStudentGroups();
  const {
    isLoading: isCategoryLoading,
    isFetching: isCategoryFetching,
    error: categoryError,
    data: categories,
    refetch,
  } = useCategories();

  const retry = () => {
    refetch();
    refetchGroups();
  };

  const isLoading =
    isGroupsLoading ||
    isGroupsFetching ||
    isCategoryFetching ||
    isCategoryLoading;

  return (
    <PageLayout
      loading={isLoading}
      error={groupsError || categoryError}
      onRetry={() => retry()}
    >
      <Formik
        initialValues={{
          name: initialAssessment?.name,
          description: initialAssessment?.description,
          group_id: initialAssessment?.group_id,
          category_id: initialAssessment?.category_id,
          time_in_mins: initialAssessment?.time_in_mins,
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={validator}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form
            style={{ textAlign: "center", marginTop: 24, marginBottom: 24 }}
          >
            <TextField
              type="text"
              name="name"
              error={!!(errors.name && touched.name)}
              helperText={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.name || ""}
              label="Assessment Name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <TextField
              type="text"
              name="description"
              error={!!(errors.description && touched.description)}
              helperText={errors.description}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description || ""}
              label="Assessment Description"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <TextField
              type="text"
              name="category_id"
              error={!!(errors.category_id && touched.category_id)}
              helperText={errors.category_id}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.category_id || ""}
              label="Subject"
              variant="outlined"
              fullWidth
              select
              placeholder="Select subject to use for this assessment"
              style={{ marginBottom: 24, textAlign: "left" }}
            >
              {(categories?.data || []).map((category) => {
                return (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name?.toUpperCase()}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              type="text"
              name="group_id"
              error={!!(errors.group_id && touched.group_id)}
              helperText={errors.group_id}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.group_id || ""}
              label="Student Group"
              variant="outlined"
              fullWidth
              select
              placeholder="Select group of students to target"
              style={{ marginBottom: 24, textAlign: "left" }}
            >
              {(groups?.data || []).map((group) => {
                return (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name?.toUpperCase()}
                  </MenuItem>
                );
              })}
            </TextField>

            <TextField
              type="number"
              name="time_in_mins"
              error={!!(errors.time_in_mins && touched.time_in_mins)}
              helperText={errors.time_in_mins}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.time_in_mins || ""}
              label="Assessment Time"
              variant="outlined"
              placeholder="Time duration for this assessment in mins"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <Box display="flex" justifyContent="flex-end">
              <Button
                type="submit"
                variant="contained"
                style={{ marginTop: 32, minWidth: 180 }}
                loading={isSubmitting}
              >
                SUBMIT
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};

export default AssessmentForm;
