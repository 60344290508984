import { get } from "api";
import { QueryOptions, useQuery } from "react-query";
import { IDashboardInfo } from "types/dashboard";
import { GenericAPIResponse } from "types/generic";

export const useDashboardInfo = (
  options?: QueryOptions<GenericAPIResponse<IDashboardInfo>>
) => {
  return useQuery<GenericAPIResponse<IDashboardInfo>, any>(
    ["dashboard/"],
    () => get("admin/dashboard"),
    { ...(options || {}) }
  );
};
