import { Box, Stack } from "@mui/material";
import Table, { RowData } from "components/Table";
import Button from "components/button/Button";
import { useMemo } from "react";
import { IAccount } from "types/auth";
import { IStudent } from "types/students";

interface IStudentTableProps {
  students: IStudent[];
  onAddStudentClicked?: Function;
  onDeleteStudent?: (student: IStudent) => void;
  onEditStudent?: (student: IStudent) => void;
  disableActionsColumn?: boolean;
  title?: string;
}

const StudentsListTable = ({
  students,
  onAddStudentClicked,
  onDeleteStudent,
  onEditStudent,
  disableActionsColumn,
  title,
}: IStudentTableProps) => {
  const data = useMemo(() => {
    return students.map((student, pos) => {
      return { ...student, index: pos + 1 };
    });
  }, [students]);

  return (
    <Table
      title={{
        label: title || "Students",
      }}
      ctaLabel="Add Student"
      onCTAClicked={onAddStudentClicked}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "name",
          label: "Name",
          renderer: (item: RowData) => {
            const account = item.account as IAccount;
            return account.name;
          },
        },
        {
          id: "email",
          label: "Email",
          renderer: (item: RowData) => item.account.email,
        },
        {
          id: "group",
          label: "GROUP",
          renderer: (item: RowData) => item.group?.name || "-",
        },
        {
          id: "gender",
          label: "Gender",
        },
        {
          id: "address",
          label: "Address",
        },
        {
          id: "actions",
          label: "Actions",
          disabled: disableActionsColumn,
          renderer: (item: RowData) => {
            return (
              <Stack direction="row" gap="12px" justifyContent="center">
                {onEditStudent && (
                  <Button
                    onClick={() => {
                      onEditStudent(item as IStudent);
                    }}
                    sx={{
                      minWidth: "max-content !important",
                      padding: "8px 28px !important",
                    }}
                    variant="contained"
                  >
                    EDIT
                  </Button>
                )}

                {onDeleteStudent && (
                  <Button
                    onClick={() => onDeleteStudent(item as IStudent)}
                    variant="contained"
                    sx={{ minWidth: "max-content !important" }}
                    color="error"
                  >
                    DELETE
                  </Button>
                )}
              </Stack>
            );
          },
        },
      ]}
      rows={data}
    />
  );
};

export default StudentsListTable;
