import { Box, Stack } from "@mui/material";
import Table, { RowData } from "components/Table";
import Button from "components/button/Button";
import { useMemo } from "react";
import { IStudentGroup } from "types/students";

interface IStudentGroupTableProps {
  groups: IStudentGroup[];
  onAddGroupClicked?: Function;
  onDeleteGroupClicked: (group: IStudentGroup) => void;
  onEditGroupClicked: (group: IStudentGroup) => void;
}

const StudentGroupListTable = ({
  groups,
  onAddGroupClicked,
  onDeleteGroupClicked,
  onEditGroupClicked,
}: IStudentGroupTableProps) => {
  const data = useMemo(() => {
    return groups.map((group, pos) => {
      return { ...group, index: pos + 1 };
    });
  }, [groups]);

  return (
    <Table
      title={{
        label: "Student Level",
      }}
      ctaLabel="ADD LEVEL"
      onCTAClicked={() => onAddGroupClicked && onAddGroupClicked()}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "name",
          label: "Name",
        },
        {
          id: "actions",
          label: "Actions",
          renderer: (item: RowData) => {
            return (
              <Stack direction="row" justifyContent="center" gap="12px">
                <Button
                  onClick={() => {
                    onEditGroupClicked &&
                      onEditGroupClicked(item as IStudentGroup);
                  }}
                  variant="contained"
                >
                  EDIT
                </Button>

                <Button
                  onClick={() =>
                    onDeleteGroupClicked &&
                    onDeleteGroupClicked(item as IStudentGroup)
                  }
                  variant="contained"
                  color="error"
                >
                  DELETE
                </Button>
              </Stack>
            );
          },
        },
      ]}
      rows={data}
    />
  );
};

export default StudentGroupListTable;
