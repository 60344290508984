import { Question } from "types/questions";
import { TableHeaderNode } from "components/Common";
import Table, { RowData } from "components/Table";
import ReactQuill from "react-quill";
import { Box } from "@mui/material";
import { NavLink, useHistory } from "react-router-dom";
import {
  addQuestionsUrl,
  editQuestionUrl,
  viewCategoryDetailsUrl,
} from "config/urls";
import { useMemo } from "react";
import { primary } from "config/colors";

interface IQuestionsTableProps {
  questions: Question[];
}

/* Renders questions*/
const QuestionsTable = ({ questions }: IQuestionsTableProps) => {
  const data = useMemo(() => {
    return questions.map((value, pos) => ({
      ...value,
      index: pos + 1,
    }));
  }, [questions]);

  return (
    <Table
      title={{
        label: "Recent Questions",
      }}
      rows={data}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "category",
          label: "SUBJECT",
          renderer: (item: RowData) => {
            const value = item as Question;
            return value?.category ? (
              <NavLink
                style={{ color: primary, textDecoration: "none" }}
                to={viewCategoryDetailsUrl(value?.category.id)}
              >
                {value.category.name}
              </NavLink>
            ) : (
              "-"
            );
          },
        },
        {
          id: "description",
          label: "DESCRIPTION",
          renderer: (item: RowData) => {
            const rowItem = item as Question;
            return (
              <ReactQuill
                readOnly
                value={rowItem.description}
                className="readonly-quill"
              />
            );
          },
        },
        {
          id: "score",
          label: "ATTAINABLE SCORE",
        },
        {
          id: "options",
          label: "NUMBER OF OPTIONS",
          renderer: (item: RowData) => {
            const rowItem = item as Question;
            return <b>{rowItem.options?.length || "-"}</b>;
          },
        },
      ]}
    />
  );
};

export default QuestionsTable;
