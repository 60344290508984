import { Grid } from "@mui/material";
import AttemptsTable from "components/Assessments/AttemptsTable";
import PageLayout from "components/PageLayout";
import StatsCard from "components/StatsCard";
import { numberWithCommas } from "config/number";
import { useAssessmentDetails } from "hooks/assessments";
import React from "react";
import { useParams } from "react-router-dom";

const AssessmentDetailsView = () => {
  const assessmentId = useParams<{ id: string }>().id;

  const { isLoading, isFetching, data, error, refetch } =
    useAssessmentDetails(assessmentId);

  const assessment = data?.data;

  return (
    <PageLayout loading={isLoading || isFetching} error={error} onRetry={()=>refetch()}>
      <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 3 }}>
        <Grid item xs={6} sm={3} md={3}>
          <StatsCard
            bgColor="#18b5ca"
            hoverBg="#33a1b0"
            body={numberWithCommas(assessment?.total_points || 0)}
            footer="EXPECTED TOTAL SCORE"
          />
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <StatsCard
            bgColor="#7130f2"
            hoverBg="#5028a1"
            body={assessment?.category?.name || "-"}
            footer="SUBJECT NAME"
          />
        </Grid>

        <Grid item xs={6} sm={3} md={3}>
          <StatsCard
            hoverBg="#283db5"
            body={assessment?.group?.name || "-"}
            footer="STUDENT GROUP"
          />
        </Grid>

        <Grid item xs={6} sm={3} md={3}>
          <StatsCard
            hoverBg="#f58d42"
            bgColor="#f5a142"
            body={numberWithCommas(assessment?.numOfQuestions || 0)}
            footer="NUMBER OF QUESTIONS"
          />
        </Grid>
      </Grid>

    <div style={{marginTop: 32}}>
        <AttemptsTable attempts={assessment?.attempts || []}/>
    </div>

    </PageLayout>
  );
};

export default AssessmentDetailsView;
