import { Box, Stack } from "@mui/material";
import { TableHeaderNode } from "components/Common";
import Status from "components/Status";
import Table, { RowData } from "components/Table";
import { Category } from "types/questions";
import { NavLink, useHistory } from "react-router-dom";
import { addQuestionsUrl, viewCategoryDetailsUrl } from "config/urls";
import Dropdown from "components/Dropdown";
import { Add, Delete, Edit } from "@mui/icons-material";
import { useMemo } from "react";
import Button from "components/button/Button";

interface ICategoriesTableProps {
  categories: Category[];
  onDeleteCategoryClicked: (category: Category) => Promise<void> | any;
  onEditCategoryClicked: (category: Category) => Promise<void> | any;
  onAddCategoryClicked: Function;
}

const CategoriesTable = ({
  categories,
  onDeleteCategoryClicked,
  onEditCategoryClicked,
  onAddCategoryClicked,
}: ICategoriesTableProps) => {
  const history = useHistory();

  const data = useMemo(() => {
    return categories.map((value, pos) => ({
      ...value,
      index: pos + 1,
    }));
  }, [categories]);

  return (
    <Table
      title={{
        label: "Subject",
      }}
      ctaLabel="Add Subject"
      onCTAClicked={onAddCategoryClicked}
      preHeaderNode={
        <TableHeaderNode style={{ marginTop: 16 }} title="Subject" />
      }
      rows={data}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "name",
          label: "NAME",
          renderer: (item: RowData) => {
            const rowItem = item as Category;

            return (
              <NavLink
                style={{
                  color: "#07978c",
                  cursor: "pointer",
                  textDecoration: "none",
                }}
                to={viewCategoryDetailsUrl(rowItem.id)}
              >
                {rowItem.name}
              </NavLink>
            );
          },
        },
        {
          id: "numOfQuestions",
          label: "NUMBER OF QUESTIONS",
        },
        {
          id: "",
          label: "ACTIONS",
          renderer: (item: RowData) => {
            const rowItem = item as Category;
            return (
              <Stack direction="row" justifyContent="center">
                <Button
                  variant="contained"
                  onClick={() => {
                    history.push(viewCategoryDetailsUrl(rowItem.id));
                  }}
                >
                  VIEW Subject
                </Button>

                <Dropdown
                  containerStyle={{ marginLeft: 16, display: "inline" }}
                  title="Actions"
                  buttonProps={{
                    sx: {
                      borderRadius: 25,
                      background: "none !important",
                      border: "1px solid #CCC",
                      color: "black",
                    },
                  }}
                  items={[
                    {
                      label: "Edit",
                      icon: <Edit />,
                      onClick: () => onEditCategoryClicked(rowItem),
                    },
                    {
                      label: "DELETE",
                      icon: <Delete />,
                      onClick: () => onDeleteCategoryClicked(rowItem),
                    },
                    {
                      label: "ADD QUESTIONS",
                      icon: <Add />,
                      onClick: () => history.push(addQuestionsUrl(rowItem.id)),
                    },
                  ]}
                />
              </Stack>
            );
          },
        },
      ]}
    />
  );
};

export default CategoriesTable;
