import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DashboardRoute from "./DashboardRoute";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "views/auth/Signin";
import { getToken } from "api/cookie";
import { Box } from "@mui/material";
import NotFound from 'components/NotFound'
import AppBar from 'components/Appbar'

/** Setups up the application route */

const AppRoute = () => {
  return (
    <div id="app-container">
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/signin" />} />
        <Route path="/signin" exact component={SignIn} />
        <Route
          path="/dashboard"
          render={(props) => <DashboardRoute {...props} />}
        />
        <Route path="*" render={() => <Box><AppBar/><NotFound/> </Box>} />
      </Switch>
      <ToastContainer />
    </div>
  );
};

export default AppRoute;
