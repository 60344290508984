import { get, post } from "api";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import {
  IAccount,
  IAuthData,
  ILoginFormValues,
} from "types/auth";
import { GenericAPIResponse } from "types/generic";

export const useLoginMutation = (
  options?: MutationOptions<GenericAPIResponse<IAuthData>>
) => {
  return useMutation(
    (value: ILoginFormValues) => post("account/login", value),
    { ...(options || {}) }
  );
};

export const useAuthProfile = (options?: QueryOptions<IAccount>) => {
  return useQuery<IAccount, any>(
    ["account/profile"],
    () => get("account/profile"),
    { ...(options || {}), cacheTime: 0, retry: false } // caching is disabled for profile route.
  );
};

