import { Modal } from "@mui/material";
import ModalContainer from "components/ModalContainer";
import { IStudent } from "types/students";
import AddOrEditStudentForm, { IFormStudent } from "./AddOrEditStudentForm";

interface IAddStudentModalProps {
  onSubmit: (student: IFormStudent) => Promise<void | any>;
  onCancel: Function;
  open?: boolean;
  inEditMode?: boolean;
  initialStudent?: IStudent;
}

const AddStudentModal = ({
  onSubmit,
  onCancel,
  open = false,
  inEditMode,
  initialStudent,
}: IAddStudentModalProps) => {
  return (
    <Modal open={open} onClose={() => onCancel && onCancel()}>
      <ModalContainer
        title={inEditMode ? "EDIT STUDENT" : "ADD STUDENT"}
        handleClose={onCancel}
        style={{ width: 640 }}
      >
        <AddOrEditStudentForm
          inEditMode={inEditMode}
          onSubmit={onSubmit}
          initialStudent={inEditMode ? initialStudent : undefined}
        />
      </ModalContainer>
    </Modal>
  );
};

export default AddStudentModal;
