import TextField from "components/Form/TextField";
import { Box, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import ModalContainer from "components/ModalContainer";
import Button from "components/button/Button";

interface IAddCategoryModalProps {
  onSubmit: (name: string) => Promise<void | any>;
  onCancel: Function;
  open?: boolean;
  initialName?: string;
  title?: string;
}

const addCategoryFormValidator = Yup.object().shape({
  name: Yup.string()
    .required("Enter subject name")
    .min(3, "Name must be more than two characters"),
});

const AddCategoryModal = ({
  onSubmit,
  onCancel,
  initialName,
  open = false,
  title = "ADD SUBJECT",
}: IAddCategoryModalProps) => {
  const initialValues = { name: initialName };

  return (
    <Modal open={open} onClose={() => onCancel && onCancel()}>
      <ModalContainer
        title={title}
        handleClose={onCancel}
        style={{ width: 450 }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            console.log({ values });
            setSubmitting(true);
            await onSubmit(values.name!);
            setSubmitting(false);
          }}
          validationSchema={addCategoryFormValidator}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form
              style={{ textAlign: "center", marginTop: 24, marginBottom: 24 }}
            >
              <TextField
                type="text"
                name="name"
                error={!!(errors.name && touched.name)}
                helperText={errors.name}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.name || ""}
                label="Subject Name"
                variant="outlined"
                fullWidth
              />

              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={isSubmitting}
                  style={{ marginTop: 32, minWidth: 180 }}
                >
                  SUBMIT
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </ModalContainer>
    </Modal>
  );
};

export default AddCategoryModal;
