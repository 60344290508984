import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

interface ICountdownProps {
  durationInSec: number;
  onEllapsed: () => void;
}

const Countdown = ({ durationInSec, onEllapsed }: ICountdownProps) => {
  const [secondsLeft, setSecondsLeft] = useState(durationInSec);

  useEffect(() => {
    if (secondsLeft > 0) {
      const id = setTimeout(() => {
        if (secondsLeft - 1 <= 0) {
          onEllapsed();
        }
        setSecondsLeft((left) => left - 1);
      }, 1000);

      return () => clearTimeout(id);
    }
  }, [secondsLeft]);

  let minutes = parseInt((secondsLeft / 60).toString());
  let seconds = secondsLeft - minutes * 60;

  minutes = minutes < 0 ? 0 : minutes;
  seconds = seconds < 0 ? 0 : seconds;

  return (
    <div>
      <Typography fontWeight="bold" variant="h4">
        {minutes}:{seconds}
      </Typography>
    </div>
  );
};

export default Countdown;
