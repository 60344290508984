import { Typography } from "@mui/material";
import { AxiosError } from "axios";
import DeleteModal from "components/DeleteModal";
import PageLayout from "components/PageLayout";
import {
  useAddCategory,
  useCategories,
  useDeleteCategory,
  useUpdateCategory,
} from "hooks/categories";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import AddCategoryModal from "components/Questions/AddCategoryModal";
import CategoriesTable from "components/Questions/CategoryListTable";
import { useHistory } from "react-router-dom";
import { viewCategoryDetailsUrl } from "config/urls";
import { Category } from "types/questions";

const CategoryView = () => {
  const history = useHistory();
  const [showDeleteCategoryModal, setShowDeleteCategoryModal] =
    useState<boolean>(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>();
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);

  const { data, isLoading, error, refetch, isFetching } = useCategories();

  const categories = useMemo(() => {
    return (data?.data || []).map((data: any, index: number) => ({
      ...data,
      index: index + 1,
    }));
  }, [data]);

  const { mutateAsync: addCategoryAPIRequest } = useAddCategory();
  const { mutateAsync: updateCategoryAPIRequest } = useUpdateCategory();
  const { mutateAsync: deleteCategoryAPIRequest } = useDeleteCategory();

  const [selectedCategory, setSelectedCategory] = useState<Category | null>();

  const submitCategory = async (name: string) => {
    try {
      const result = await (selectedCategory
        ? updateCategoryAPIRequest({ id: selectedCategory.id, name })
        : addCategoryAPIRequest({ name }));
      toast(
        selectedCategory
          ? "Subject updated successfully"
          : "Subject added successfully",
        { type: "success" }
      );
      refetch();
      if (!selectedCategory) {
        history.push(viewCategoryDetailsUrl(result.data.id));
      }
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't save subject",
        { type: "error" }
      );
    } finally {
      setShowAddCategoryModal(false);
      setSelectedCategory(null);
    }
  };

  const deleteCategory = async (id: number) => {
    try {
      await deleteCategoryAPIRequest(id);
      toast("Subject deleted successfully", { type: "success" });
      refetch();
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't delete subject",
        { type: "error" }
      );
    } finally {
      clearSelection();
    }
  };

  const confirmDeleteCategory = (id: number) => {
    setSelectedCategoryId(id);
    setShowDeleteCategoryModal(true);
  };

  const clearSelection = () => {
    setShowDeleteCategoryModal(false);
    setSelectedCategoryId(0);
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <CategoriesTable
        categories={categories}
        onDeleteCategoryClicked={(category) =>
          confirmDeleteCategory(category.id)
        }
        onAddCategoryClicked={() => setShowAddCategoryModal(true)}
        onEditCategoryClicked={(category) => {
          setSelectedCategory(category);
          setShowAddCategoryModal(true);
        }}
      />

      <DeleteModal
        prompt="You want to delete this subject? This action cannot be reversed"
        title="Are you sure"
        onCancel={() => {
          clearSelection();
        }}
        open={showDeleteCategoryModal}
        onConfirm={() => deleteCategory(selectedCategoryId!)}
      />

      <AddCategoryModal
        open={showAddCategoryModal}
        onSubmit={submitCategory}
        title={selectedCategory ? "EDIT SUBJECT" : "ADD SUBJECT"}
        initialName={selectedCategory?.name}
        onCancel={() => {
          setSelectedCategory(null);
          setShowAddCategoryModal(false);
        }}
      />
    </PageLayout>
  );
};

export default CategoryView;
