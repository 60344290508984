import React, { useEffect, useState } from "react";
import TextField from "components/Form/TextField";
import { Box, Modal } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import RichEditor from "components/RichEditor";
import { error } from "config/colors";
import { v4 as uuidv4 } from "uuid";
import IconButton from "@mui/material/IconButton";
import { Delete } from "@mui/icons-material";
import Button from "components/button/Button";

export interface Question {
  description: string;
  options: FormOption[];
  score: number;
}

interface FormOption {
  description: string;
  uid: string | number;
  is_answer?: boolean;
}

interface IAddQuestionFormProps {
  onSubmit: (question: Question) => Promise<void | any>;
  initialQuestion?: Question;
  inEditMode?: boolean;
  autoClearFormOnSubmit?: boolean;
}

const validationSchema = Yup.object().shape({
  points: Yup.number()
    .min(1, "Enter positive score")
    .required("Enter attainable score for this question"),
});

const makeFormOptions = (count: number) => {
  const options: FormOption[] = [];

  for (let i = 0; i < count; i++) {
    options.push({
      description: "",
      uid: uuidv4(),
    });
  }
  return options;
};

const AddQuestionForm = ({
  onSubmit,
  initialQuestion,
  inEditMode,
  autoClearFormOnSubmit,
}: IAddQuestionFormProps) => {
  const [options, setOptions] = useState<FormOption[]>(makeFormOptions(2));
  const [selectedOptionId, setSelectedOptionId] = useState<string | number>("");
  const [questionDescription, setQuestionDescription] = useState<string>("");
  const [formErrorMessage, setFormErrorMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (initialQuestion) {
      //update question description from initial question prop.
      setQuestionDescription(initialQuestion?.description || "");

      const optionsToUse = initialQuestion?.options || makeFormOptions(2);
      setOptions(optionsToUse);

      const selectedOptionId = optionsToUse.find(
        (option) => option.is_answer
      )?.uid;
      setSelectedOptionId(selectedOptionId || "");
    }
  }, [initialQuestion]);

  const updateOption = (uid: string | number, description: string) => {
    const newOptions = [...options].map((option) => {
      if (option.uid === uid) {
        return { uid, description } as FormOption;
      }
      return option;
    });
    setOptions(newOptions);
  };

  const addToOptions = () => {
    const newOptions = [...options, ...makeFormOptions(1)];
    setOptions(newOptions);
  };

  const deleteOption = (uid: string | number) => {
    const newOptions = [...options].filter((option) => option.uid !== uid);
    setOptions(newOptions);
  };

  const validateFormData = () => {
    let error = "";
    if (!questionDescription || questionDescription.length < 12) {
      error =
        "Make sure the question description field is filled with not less than 12 characters";
    } else if (options.some((option) => !option.description)) {
      error = "Empty option texts aren't allowed";
    } else if (!options.some((option) => option.uid === selectedOptionId)) {
      error = "Please select the option that correctly answers the question";
    }

    if (error) {
      setFormErrorMessage(error);
      return false;
    } else {
      setFormErrorMessage("");
    }
    return true;
  };

  const formatAndSubmit = async (values: any) => {
    const formatedValues = {
      score: values.points,
      description: questionDescription,
      options: options.map((option) => {
        return { ...option, is_answer: option.uid === selectedOptionId };
      }),
    };

    if (validateFormData()) {
      await onSubmit(formatedValues);
      if (autoClearFormOnSubmit) {
        setQuestionDescription("");
        setOptions(makeFormOptions(2));
      }
    }
  };

  return (
    <Formik
      validationSchema={validationSchema}
      enableReinitialize
      initialValues={{
        points: initialQuestion?.score || 1,
      }}
      onSubmit={async (values: any, { resetForm }) => {
        setIsSubmitting(true);
        formatAndSubmit(values).finally(() => {
          setIsSubmitting(false);
          if (autoClearFormOnSubmit) {
            resetForm();
          }
        });
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur }) => {
        return (
          <Form style={{ textAlign: "center" }}>
            <div style={{ padding: 16 }}>
              <TextFieldContainer>
                <TextField
                  type="number"
                  name="points"
                  error={!!(errors.points && touched.points)}
                  helperText={errors.points}
                  onChange={(event) => {
                    console.log({ value: event.target.value });
                    handleChange(event);
                  }}
                  onBlur={handleBlur}
                  value={values.points}
                  label="Attainable Points"
                  variant="outlined"
                  fullWidth
                />
              </TextFieldContainer>

              <TextFieldContainer>
                <RichEditor
                  value={questionDescription}
                  onTextChanged={setQuestionDescription}
                  placeholder="Enter question (e.g) what is the area of a cirle of radius 12 metres using 3.14159 as pie"
                />
              </TextFieldContainer>

              {options.map((option, index) => {
                return (
                  <TextFieldContainer
                    key={option.uid}
                    direction="row"
                    style={{ alignItems: "center" }}
                  >
                    <input
                      name="selected-option-id"
                      type="radio"
                      value={option.uid}
                      checked={option.uid === selectedOptionId}
                      onChange={(event) => {
                        setSelectedOptionId(event.target.value);
                      }}
                    />
                    <TextField
                      style={{ marginLeft: 16 }}
                      onChange={(event) =>
                        updateOption(option.uid, event.target.value)
                      }
                      value={option.description}
                      label="Option"
                      placeholder="Enter option text"
                      variant="outlined"
                      fullWidth
                    />
                    {index >= 2 && (
                      <IconButton
                        color="error"
                        onClick={() => deleteOption(option.uid)}
                        sx={{ marginLeft: 2 }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </TextFieldContainer>
                );
              })}

              {formErrorMessage && (
                <ErrorContainer>{formErrorMessage}</ErrorContainer>
              )}
            </div>

            <Footer>
              <Button
                type="button"
                variant="contained"
                color="secondary"
                onClick={() => addToOptions()}
                disabled={isSubmitting}
              >
                ADD OPTION
              </Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
              >
                {inEditMode ? "UPDATE QUESTION" : "ADD QUESTION"}
              </Button>
            </Footer>
          </Form>
        );
      }}
    </Formik>
  );
};

/**
 * ---------------------
 * STYLED COMPONENTS
 * ---------------------
 */

const TextFieldContainer = styled.div<{ direction?: "row" | "column" }>`
  margin-top: 24px;
  display: flex;
  flex-direction: ${(props) => props.direction || "column"};
`;

const Footer = styled.div`
  display: flex;
  border-top: dashed 2px #efefee;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 32px;
  padding: 16px;
`;

const ErrorContainer = styled.div`
  text-align: left;
  margin-top: 16px;
  color: ${error};
`;

export default AddQuestionForm;
