import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import {
  useAddStudent,
  useDeleteStudent,
  useStudents,
  useUpdateStudent,
} from "hooks/students";
import StudentsListTable from "components/Students/StudentsListTable";
import AddStudentModal from "components/Students/AddStudentModal";
import { IFormStudent } from "components/Students/AddOrEditStudentForm";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { IStudent } from "types/students";
import DeleteModal from "components/DeleteModal";
import StudentGroupListTable from "components/Students/StudentGroupListTable";

const StudentsView = () => {
  const {
    isLoading,
    isFetching,
    error,
    refetch,
    data: students,
  } = useStudents();

  const { mutateAsync: submitStudentAsync } = useAddStudent();
  const { mutateAsync: updateStudentAsync } = useUpdateStudent();
  const { mutateAsync: deleteStudentFromServer } = useDeleteStudent();

  const [showAddOrEditStudentModal, setShowAddOrEditStudentModal] =
    useState(false);
  const [selectedStudent, setSelectedStudent] = useState<IStudent | null>(null);
  const [showDeleteStudentModal, setShowDeleteStudentModal] = useState(false);

  const submitStudentToServer = async (student: IFormStudent) => {
    try {
      const result = await (selectedStudent
        ? updateStudentAsync({ student, id: selectedStudent.id })
        : submitStudentAsync(student));
      toast(
        result.message || selectedStudent
          ? "Student updated successfully"
          : "Student saved successfully!"
      );
      refetch();
      setSelectedStudent(null);
      setShowAddOrEditStudentModal(false);
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't save student",
        { type: "error" }
      );
    } 
  };

  const deleteStudent = async (id: number) => {
    try {
      const result = await deleteStudentFromServer(id);
      toast(result.message || "Student deleted successfully!");
      refetch();
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't delete student",
        { type: "error" }
      );
    } finally {
      setShowDeleteStudentModal(false);
      setSelectedStudent(null);
    }
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <StudentsListTable
        students={students?.data || []}
        onAddStudentClicked={() => setShowAddOrEditStudentModal(true)}
        onDeleteStudent={(student) => {
          setSelectedStudent(student);
          setShowDeleteStudentModal(true);
        }}
        onEditStudent={(student) => {
          setSelectedStudent(student);
          setShowAddOrEditStudentModal(true);
        }}
      />

      <AddStudentModal
        open={showAddOrEditStudentModal}
        onCancel={() => {
          setShowAddOrEditStudentModal(false);
          setSelectedStudent(null);
        }}
        onSubmit={submitStudentToServer}
        initialStudent={selectedStudent || undefined}
        inEditMode={Boolean(selectedStudent)}
      />

      <DeleteModal
        title="Delete Student"
        prompt="Are you sure you want to delete student?"
        open={Boolean(selectedStudent && showDeleteStudentModal)}
        onCancel={() => {
          setSelectedStudent(null);
          setShowDeleteStudentModal(false);
        }}
        onConfirm={async () => {
          await deleteStudent(selectedStudent!.id);
        }}
      />
    </PageLayout>
  );
};

export default StudentsView;
