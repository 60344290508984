import { Box, Grid } from "@mui/material";
import QuestionsTable from "components/Dashboard/QuestionsTable";
import PageLayout from "components/PageLayout";
import StatsCard from "components/StatsCard";
import StudentsListTable from "components/Students/StudentsListTable";
import { numberWithCommas } from "config/number";
import { useDashboardInfo } from "hooks/dashboard";

const DashboardHome = () => {
  const { isLoading, isFetching, error, data, refetch } = useDashboardInfo();

  const dashboardInfo = data?.data;

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <Grid container spacing={2} sx={{ marginTop: 2, marginBottom: 3 }}>
        <Grid item xs={6} sm={4} md={4}>
          <StatsCard
            bgColor="#18b5ca"
            hoverBg="#33a1b0"
            body={numberWithCommas(dashboardInfo?.stats?.numOfStudents || 0)}
            footer="NUMBER OF STUDENTS"
          />
        </Grid>

        <Grid item xs={6} sm={4} md={4}>
          <StatsCard
            bgColor="#7130f2"
            hoverBg="#5028a1"
            body={numberWithCommas(dashboardInfo?.stats?.numOfQuestions || 0)}
            footer="NUMBER OF QUESTIONS"
          />
        </Grid>

        <Grid item xs={6} sm={4} md={4}>
          <StatsCard
            hoverBg="#283db5"
            body={numberWithCommas(dashboardInfo?.stats?.numOfCategories || 0)}
            footer="NUMBER OF SUBJECTS"
          />
        </Grid>
      </Grid>

      <StudentsListTable
        title="Recent Students"
        students={dashboardInfo?.students || []}
        disableActionsColumn
      />

      <div style={{ marginTop: 48 }}>
        <QuestionsTable questions={dashboardInfo?.questions || []} />
      </div>
    </PageLayout>
  );
};

export default DashboardHome;
