import React from "react";
import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import Lottie from "react-lottie";
import AnimatedError from "../assets/animated/error.json";
import AnimatedLoading from "../assets/animated/loading.json";
import Button from "./button/Button";

interface IPageLayoutProps {
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  children: React.ReactNode | React.ReactNode[];
  onRetry?: () => any;
}

const PageLayout = ({
  loading,
  error,
  errorMessage,
  onRetry,
  children,
}: IPageLayoutProps) => {
  errorMessage =
    errorMessage ||
    "It's not you, it's us, but don't fret. Give us another try";

  const renderLoading = () => (
    <Stack alignItems="center" justifyContent="center">
      <Box display="flex" alignItems="center" height="40vh">
        <CircularProgress thickness={10} size={40} />
      </Box>
    </Stack>
  );

  const renderError = () => (
    <Box textAlign="center" style={{ marginTop: "10vh" }}>
      <Lottie
        options={{ loop: true, autoplay: true, animationData: AnimatedError }}
        width={300}
        height={300}
        isClickToPauseDisabled
      />

      <Typography variant="subtitle1" fontWeight="bold" mt={-5}>
        Something went wrong
      </Typography>
      <Typography variant="body1" mb={4} mt={1}>
        {errorMessage}
      </Typography>

      <Box display="flex" justifyContent="center">
        <Button onClick={onRetry} variant="contained" style={{ minWidth: 180 }}>
          TRY AGAIN
        </Button>
      </Box>
    </Box>
  );

  return loading ? renderLoading() : error ? renderError() : <>{children}</>;
};

export default PageLayout;
