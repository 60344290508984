import { Delete, Edit, SwitchAccessShortcut } from "@mui/icons-material";
import { Box } from "@mui/material";
import Dropdown from "components/Dropdown";
import Status from "components/Status";
import Table, { RowData } from "components/Table";
import { primary } from "config/colors";
import { viewAssessmentDetailsUrl } from "config/urls";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Assessment } from "types/assessments";

interface IAssesementTableProps {
  assessments: Assessment[];
  onAddAssessmentClicked?: Function;
  onDeleteAssessment?: (assessment: Assessment) => void;
  onEditAssessment?: (assessment: Assessment) => void;
  onUpdateAssessmentStatus?: (assessment: Assessment) => void;
}

const AssessmentTable = ({
  assessments,
  onAddAssessmentClicked,
  onDeleteAssessment,
  onEditAssessment,
  onUpdateAssessmentStatus,
}: IAssesementTableProps) => {
  const data = useMemo(() => {
    return assessments.map((assessment, pos) => {
      return { ...assessment, index: pos + 1 };
    });
  }, [assessments]);

  return (
    <Table
      title={{
        label: "Assessments",
      }}
      ctaLabel="Add Assessment"
      onCTAClicked={onAddAssessmentClicked}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "name",
          label: "NAME",
          renderer: (item: RowData) => {
            return (
              <NavLink
                style={{ color: primary, textDecoration: "none" }}
                to={viewAssessmentDetailsUrl(item.id)}
              >
                {item.name}
              </NavLink>
            );
          },
        },
        {
          id: "total_points",
          label: "TOTAL POINTS",
        },
        {
          id: "time_in_mins",
          label: "DURATION IN MINUTES",
        },
        {
          id: "group",
          label: "GROUP",
          renderer: (item: RowData) => item.group?.name || "-",
        },
        {
          id: "category",
          label: "CATEGORY",
          renderer: (item: RowData) => item.category?.name || "-",
        },
        {
          id: "status",
          label: "STATUS",
          renderer: (item: RowData) => {
            const value = item as Assessment;
            return (
              <Status
                status={value.status ? "active" : "inative"}
                label={value.status ? "ACTIVE" : "IN-ACTIVE"}
              />
            );
          },
        },
        {
          id: "actions",
          label: "Actions",
          renderer: (item: RowData) => {
            const value = item as Assessment;
            return (
              <Box flexDirection="row">
                <Dropdown
                  containerStyle={{ marginLeft: 16, display: "inline" }}
                  title="ACTIONS"
                  buttonProps={{
                    sx: {
                      borderRadius: 25,
                      background: "none !important",
                      border: "1px solid #CCC",
                      color: "black",
                    },
                  }}
                  items={[
                    {
                      label: "EDIT",
                      icon: <Edit />,
                      onClick: () =>
                        onEditAssessment && onEditAssessment(value),
                    },
                    {
                      label: "DELETE",
                      icon: <Delete />,
                      onClick: () =>
                        onDeleteAssessment && onDeleteAssessment(value),
                    },
                    {
                      label: value.status ? "DE-ACTIVATE" : "ACTIVATE",
                      icon: <SwitchAccessShortcut />,
                      onClick: () =>
                        onUpdateAssessmentStatus &&
                        onUpdateAssessmentStatus(value),
                    },
                  ]}
                />
              </Box>
            );
          },
        },
      ]}
      rows={data}
    />
  );
};

export default AssessmentTable;
