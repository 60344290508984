import { Button } from '@mui/material';
import styled from 'styled-components'


export const Link = styled.div`
    color: #647aa8;
`

export const SubmitButton = styled(Button)`

    && {
    border-radius: 50px;
    margin-top:48px;
    padding: 18px;
    font-weight: bold;
    min-width: 250px;
    margin-bottom: 60px;
    }
`

export const TextFieldContainer = styled.div<{ direction?: 'row' | 'column' }>`
    margin-top: 24px;
    display: flex;
    flex-direction: ${props => props.direction || 'column'};
`

export const Title = styled.h1`
    font-size: 1.75rem;
    font-weight: 700;
`

export const Layout = styled.div`
  display: flex;
  min-height: 100vh;
`;

export const FormContent = styled.div`
    max-width: 500px;
    width: 100%;
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
`

export const FormContainer = styled.div`
  width: 100%;
  padding: 30px;
  padding-top: 0px;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
`;

export const Banner = styled.div`
  display: none;
  margin-top: 64px;
  background: #f5f6fa;
  @media screen and (min-width: 992px) {
    display: flex;
    flex: 0 0 50%;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
`;