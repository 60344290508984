import React from 'react';

import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '@mui/material';

type ITextFieldProps = {
    startIconNode?: React.ReactNode;
}

const TextField = ({ startIconNode, InputProps, ...props }: ITextFieldProps & MUITextFieldProps)=>{
    InputProps = InputProps || {};
    InputProps = {...InputProps, startAdornment: startIconNode || InputProps.startAdornment }
    return <MUITextField InputProps={InputProps} {...props} />
}

export default TextField;