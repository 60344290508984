import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export interface IRichEditorProps {
  onTextChanged: (value: string) => void;
  value?: string;
  placeholder?: string;
}

const RichEditor = ({
  placeholder,
  onTextChanged,
  value,
}: IRichEditorProps) => {
  const onChange = (value: string) => {
    onTextChanged && onTextChanged(value);
  };

  return (
    <ReactQuill
      placeholder={placeholder}
      theme="snow"
      modules={{
        toolbar: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          ["formula"],
          ["link"],
          ["clean"],
        ],
      }}

      value={value}
      onChange={onChange}
    />
  );
};

export default RichEditor;
