import { Card, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import AssessmentForm from "components/Assessments/AssessementForm";
import { viewAssessmentsUrl } from "config/urls";
import { useAddAssessment } from "hooks/assessments";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Assessment } from "types/assessments";

const AddAssessment = () => {
  const history = useHistory();
  const { mutateAsync: submitAssessment } = useAddAssessment();

  const submitAssessmentToServer = async (assessment: Partial<Assessment>) => {
    try {
      await submitAssessment(assessment);
      toast("Assessement added successfully", { type: "success" });
      history.replace(viewAssessmentsUrl());
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't add assessment",
        { type: "error" }
      );
    }
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={12} lg={7}>
        <Card sx={{ borderRadius: 3 }}>
          <Header>
            <Typography variant="subtitle1" fontWeight="bold">
              ADD ASSESSMENT
            </Typography>
          </Header>
            <div style={{padding: 16}}>
            <AssessmentForm onSubmit={submitAssessmentToServer} />

            </div>
        </Card>
      </Grid>
    </Grid>
  );
};

/**
 * -------------------
 * STYLED COMPONENTS
 * -------------------
 */

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px dashed #efefee;
`;

export default AddAssessment;
