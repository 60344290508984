import { AxiosError } from "axios";
import AssessmentTable from "components/Assessments/AssessmentListTable";
import ConfirmationModal from "components/ConfirmationModal";
import DeleteModal from "components/DeleteModal";
import PageLayout from "components/PageLayout";
import { addAssessmentsUrl, viewEditAssessmentUrl } from "config/urls";
import {
  useAssessments,
  useDeleteAssessment,
  useUpdateAssessmentStatus,
} from "hooks/assessments";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Assessment } from "types/assessments";

const AssessmentsView = () => {
  const history = useHistory();

  const { isLoading, isFetching, data, error, refetch } = useAssessments();
  const { mutateAsync: deleteAssessment } = useDeleteAssessment();
  const { mutateAsync: updateAssessment } = useUpdateAssessmentStatus();

  const [selectedAssessment, setSelectedAssessment] =
    useState<Assessment | null>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateStatusModal, setShowUpdateStatusModal] = useState(false);

  const deleteAssessmentFromServer = async () => {
    try {
      await deleteAssessment(selectedAssessment!.id);
      refetch();
      toast("Assessment deleted successfully", { type: "success" });
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't delete assessment",
        { type: "error" }
      );
    } finally {
      setSelectedAssessment(null);
      setShowDeleteModal(false);
    }
  };

  const updateAssessmentStatus = async () => {
    try {
      await updateAssessment({
        id: selectedAssessment!.id,
        status: !selectedAssessment!.status,
      });
      refetch();
      toast("Assessment updated successfully", { type: "success" });
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't update assessment",
        { type: "error" }
      );
    } finally {
      setSelectedAssessment(null);
      setShowUpdateStatusModal(false);
    }
  };

  return (
    <PageLayout loading={isLoading || isFetching} error={error}>
      <AssessmentTable
        assessments={data?.data || []}
        onAddAssessmentClicked={() => {
          history.push(addAssessmentsUrl());
        }}
        onDeleteAssessment={(assessment) => {
          setSelectedAssessment(assessment);
          setShowDeleteModal(true);
        }}
        onUpdateAssessmentStatus={(assessment) => {
          setSelectedAssessment(assessment);
          setShowUpdateStatusModal(true);
        }}
        onEditAssessment={(assessment) => {
          history.push(viewEditAssessmentUrl(assessment.id));
        }}
      />

      <DeleteModal
        onCancel={() => {
          setShowDeleteModal(false);
          setSelectedAssessment(null);
        }}
        onConfirm={deleteAssessmentFromServer}
        open={Boolean(showDeleteModal && selectedAssessment)}
        title="Delete Assessment"
        prompt="Are you sure you want to delete this assessment? This action cannot be reversed"
      />

      <ConfirmationModal
        open={Boolean(showUpdateStatusModal && selectedAssessment)}
        title={
          selectedAssessment?.status
            ? "DEACTIVATE ASSESSMENT"
            : "ACTIVATE ASSESSMENT"
        }
        prompt={`Are you sure you want to ${
          selectedAssessment?.status ? "de-activate" : "activate"
        } assessment?`}
        onCancel={() => {
          setSelectedAssessment(null);
          setShowUpdateStatusModal(false);
        }}
        onConfirm={updateAssessmentStatus}
      />
    </PageLayout>
  );
};

export default AssessmentsView;
