import MUICard from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import styled from "styled-components";

export interface IStatsCardProps {
  body: string;
  footer?: string;
  bgColor?: string;
  hoverBg?: string;
}

const StatsCard = ({ body, footer, bgColor = "#1f41ff", hoverBg }: IStatsCardProps) => {
  return (
    <Card
      sx={{
        boxShadow: "0 3px 6px rgb(0 0 0 / 12%)",
        background: bgColor,
        color: "white",
      }}
      hoverBg={hoverBg}
    >
      <CardContent sx={{p: 4}}>
        <Typography gutterBottom variant="h5" sx={{ fontWeight: "bold" }}>
          {body}
        </Typography>
        {footer && <Typography variant="caption">{footer}</Typography>}
      </CardContent>
    </Card>
  );
};

const Card = styled(MUICard)<{ hoverBg?: string }>`
  & :hover {
    background-color: ${(props) => props.hoverBg};
    cursor: pointer;
  }
`;

export default StatsCard;
