import { Box, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ModalContainer from "./ModalContainer";
import Button from "./button/Button";

interface IConfirmationModalProps {
  prompt: React.ReactNode | string;
  title: string;
  onCancel?: Function;
  onConfirm?: () => Promise<void | any>;
  open: boolean;
}

const ConfirmationModal = ({
  title,
  prompt,
  onCancel,
  onConfirm,
  open,
}: IConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal open={open} onClose={() => onCancel && onCancel()}>
      <ModalContainer
        title={title}
        handleClose={onCancel}
        style={{ width: 380 }}
      >
        <Typography variant="body2" textAlign="center">
          {prompt}
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          gap="16px"
          style={{ marginTop: 32 }}
        >
          <Button
            onClick={() => {
              if (onConfirm) {
                setIsLoading(true);
                onConfirm().finally(() => {
                  setIsLoading(false);
                });
              }
            }}
            variant="contained"
            color="primary"
            loading={isLoading}
          >
            CONFIRM
          </Button>
          <Button
            disabled={isLoading}
            variant="outlined"
            onClick={() => onCancel && onCancel()}
          >
            CANCEL
          </Button>
        </Stack>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
