import { Box, Modal, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ModalContainer from "./ModalContainer";
import Button from "./button/Button";

interface IDeleteModalProps {
  prompt: string;
  title: string;
  onCancel?: Function;
  onConfirm?: () => Promise<void | any>;
  open: boolean;
}

const DeleteModal = ({
  title,
  prompt,
  onCancel,
  onConfirm,
  open,
}: IDeleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal open={open} onClose={() => onCancel && onCancel()}>
      <ModalContainer
        title={title}
        handleClose={onCancel}
        style={{ width: 380 }}
      >
        <Typography variant="body2" textAlign="center">
          {prompt}
        </Typography>

        <Stack
          direction="row"
          justifyContent="center"
          gap="16px"
          style={{ marginTop: 32 }}
        >
          <Button
            onClick={() => {
              if (onConfirm) {
                setIsLoading(true);
                onConfirm().finally(() => {
                  setIsLoading(false);
                });
              }
            }}
            style={{ minWidth: 150 }}
            variant="contained"
            color="error"
            disabled={isLoading}
          >
            DELETE
          </Button>
          <Button
            style={{ minWidth: 150 }}
            variant="outlined"
            onClick={() => onCancel && onCancel()}
            disabled={isLoading}
          >
            CANCEL
          </Button>
        </Stack>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteModal;
