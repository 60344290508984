import React from "react";
import { ThemeProvider } from "@mui/material";
import Theme from "config/theme";
import AppRoute from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

//initialize katex
import katex from 'katex';
import 'katex/dist/katex.min.css';
window.katex = katex;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { staleTime: Infinity, cacheTime: 0, retry: false },
  },
});

function App() {
  return (
    <ThemeProvider theme={Theme}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
            <AppRoute />
        </BrowserRouter>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
