import { deleteRequest, get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { Assessment, AttemptAssessmentDetails, Attempts } from "types/assessments";
import { GenericAPIResponse } from "types/generic";
import { SubmissionItem } from "views/students/attempt";

export const useAssessments = (
  options?: QueryOptions<GenericAPIResponse<Assessment[]>>
) => {
  return useQuery<GenericAPIResponse<Assessment[]>, any>(
    ["assessments/"],
    () => get("admin/assessments"),
    { ...(options || {}) }
  );
};

export const useAssessmentDetails = (
  id: number | string,
  options?: QueryOptions<
    GenericAPIResponse<Assessment & { numOfQuestions: number, attempts:Attempts[] }>
  >
) => {
  return useQuery<
    GenericAPIResponse<Assessment & { numOfQuestions: number, attempts:Attempts[] }>,
    any
  >(["assessments/", id], () => get(`admin/assessments/${id}`), {
    ...(options || {}),
  });
};

export const useAddAssessment = (
  options?: MutationOptions<GenericAPIResponse<Assessment>>
) => {
  return useMutation(
    (value: Partial<Assessment>) => post("admin/assessments", value),
    {
      ...(options || {}),
    }
  );
};

export const useUpdateAssessment = (options?: MutationOptions<any>) => {
  return useMutation(
    ({
      id,
      assessment,
    }: {
      id: number | string;
      assessment: Partial<Assessment>;
    }) => put(`admin/assessments/${id}`, assessment),
    {
      ...(options || {}),
    }
  );
};

export const useDeleteAssessment = (options?: MutationOptions<any>) => {
  return useMutation(
    (id: number | string) => deleteRequest(`admin/assessments/${id}`),
    {
      ...(options || {}),
    }
  );
};

export const useUpdateAssessmentStatus = (options?: MutationOptions<any>) => {
  return useMutation(
    ({ id, status }: { id: number | string; status: boolean }) =>
      put(`admin/assessments/${id}/status`, { status }),
    {
      ...(options || {}),
    }
  );
};

export const useAddAttempt = (
  options?: MutationOptions<GenericAPIResponse<AttemptAssessmentDetails>>
) => {
  return useMutation(
    (assessmentId: number) =>
      post(`students/assessments/${assessmentId}/attempts`, {}),
    {
      ...(options || {}),
    }
  );
};

export const useSubmitAttempt = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: { answers: SubmissionItem[]; assessmentId: string | number }) =>
      post(
        `students/assessments/${value.assessmentId}/attempts/_submit`,
        value
      ),
    {
      ...(options || {}),
    }
  );
};
