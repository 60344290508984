import { Chip } from '@mui/material';
import React from 'react';

interface IStatusProps {
    status: string,
    label : string;
}

const STATUS_COLOR_MAP:any = {
    'pending': 'default',
    'processed': 'success',
    'active':'success',
    'inactive':'default',
}

const Status = ( { label, status}: IStatusProps)=>{
    return <Chip label={label} color={STATUS_COLOR_MAP[status] || 'default'} variant='outlined'/>
}

export default Status;