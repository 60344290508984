import {
  Button as MUIButton,
  ButtonProps,
  CircularProgress,
  CircularProgressProps,
} from "@mui/material";

type IButtonProps = {
  loading?: boolean;
  children: React.ReactNode;
  loadingProps?: CircularProgressProps;
  borderRadius?: string;
} & ButtonProps;

const Button = ({
  loading,
  children,
  variant = "contained",
  color = "primary",
  size = "large",
  style = {},
  loadingProps = {},
  borderRadius,
  ...rest
}: IButtonProps) => {
  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    padding: "8px 16px",
    borderRadius: borderRadius || "25px",
    minWidth: "140px",
    maxHeight: "36px",
    letterSpacing: "1px",
    ...style,
  };

  const { thickness = 6, size: circularProgressSize = 18 } = loadingProps;

  return (
    <MUIButton
      style={buttonStyle}
      size={size}
      color={color}
      variant={variant}
      {...rest}
      disabled={loading || rest.disabled}
    >
      {loading ? (
        <CircularProgress
          thickness={thickness}
          size={circularProgressSize}
          {...loadingProps}
        />
      ) : (
        <>{children}</>
      )}
    </MUIButton>
  );
};

export default Button;
