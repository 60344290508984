import { get, post, put, deleteRequest } from "api";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { GenericAPIResponse } from "types/generic";
import { Category, Question } from "types/questions";

export const useCategories = (
  options?: QueryOptions<GenericAPIResponse<Category[]>>
) => {
  return useQuery<GenericAPIResponse<Category[]>, any>(
    ["categories/"],
    () => get("admin/questions/categories"),
    { ...(options || {}) }
  );
};

export const useAddCategory = (options?: MutationOptions<GenericAPIResponse<Category>>) => {
  return useMutation(
    (value: Partial<Category>) => post("admin/questions/categories", value),
    {
      ...(options || {}),
    }
  );
};

export const useUpdateCategory = (options?: MutationOptions<any>) => {
  return useMutation(
    (value: Category) => put(`admin/questions/categories/${value.id}`, value),
    {
      ...(options || {}),
    }
  );
};

export const useDeleteCategory = (options?: MutationOptions<any>) => {
  return useMutation(
    (categoryId: number | string) =>
      deleteRequest(`admin/questions/categories/${categoryId}`),
    {
      ...(options || {}),
    }
  );
};

export const useCategoryDetails = (
  categoryId: number | string,
  options?: QueryOptions<
    GenericAPIResponse<Category & { questions: Question[] }>
  >
) => {
  return useQuery<
    GenericAPIResponse<Category & { questions: Question[] }>,
    any
>(
    ["categories/", categoryId],
    () => get(`admin/questions/categories/${categoryId}`),
    { ...(options || {}) }
  );
};

export const useDeleteQuestion = (options?: MutationOptions<any>) => {
  return useMutation(
    (questionId: number | string) =>
      deleteRequest(`admin/questions/${questionId}`),
    {
      ...(options || {}),
    }
  );
};