import { Delete, Edit } from "@mui/icons-material";
import { Button, Card, Grid, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import DeleteModal from "components/DeleteModal";
import AddQuestionForm, {
  Question as FormQuestion,
} from "components/Questions/AddQuestionForm";
import { useAddQuestion } from "hooks/questions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import ReactQuill from "react-quill";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { viewCategoryDetailsUrl } from "config/urls";

/** Renders the add question to category view */
const AddQuestions = () => {
  const [questions, setQuestions] = useState<FormQuestion[]>([]);
  const [selectedQuestionIndexToDelete, setSelectedQuestionIndexToDelete] =
    useState<number>();

    const history = useHistory();

  const [selectedQuestionToEdit, setSelectedQuestionToEdit] =
    useState<FormQuestion>();


  const routeCategoryId = useParams<{id: string}>().id;

  const { mutateAsync: submitQuestion, isLoading } = useAddQuestion();

  useEffect(() => {
    const unLoadEventHandler = () => {
      if (questions.length > 0) {
        // if we have un-saved questions.
        return "Are you sure you want to continue without saving added questions? You will lose working data if you leave this page without saving!";
      }
    };

    window.onbeforeunload = unLoadEventHandler;

    return () => {
      window.onbeforeunload = null;
    };
  }, [questions]);

  const submitQuestionsToServer = async () => {
    try {
      const result = await submitQuestion({
        questions: questions,
        category_id: Number(routeCategoryId),
      });
      toast("Question added successfully", { type: "success" });
      setQuestions([]);
      history.replace(viewCategoryDetailsUrl(routeCategoryId));
    } catch (error) {
      toast("Couldn't add question", { type: "error" });
    }
  };

  const addToQuestionList = async (question: FormQuestion) => {
    //fixes an issue where quill inserts un-neccessary line breaks.
    question.description = question.description.replaceAll("<p><br></p>", "");

    const newQuestions = [...questions, question];
    setQuestions(newQuestions);

    setSelectedQuestionToEdit(undefined);
  };

  const deleteQuestion = (index: number) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };

  const editQuestion = (index: number) => {
    const question = questions[index];
    // if editing, remove from the list so when user adds it back, we don't have to worry about updating, we simply re-add
    deleteQuestion(index);
    setSelectedQuestionToEdit(question);
  };

  return (
    <div>
      <Prompt
        when={questions.length > 0}
        message="You have un-saved changes. Are you sure you want to leave?"
      />
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={7}>
          <Card sx={{ borderRadius: 3 }}>
            <Header>
              <Typography variant="subtitle1" fontWeight="bold">
                ADD QUESTION
              </Typography>
            </Header>

            <AddQuestionForm
              initialQuestion={selectedQuestionToEdit}
              onSubmit={addToQuestionList}
              autoClearFormOnSubmit
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={12} lg={5}>
          {questions.length > 0 && (
            <Card sx={{ borderRadius: 3 }}>
              <Header>
                <Typography variant="body2" fontWeight="bold">
                  ADDED QUESTIONS
                </Typography>

                <Button
                  variant="contained"
                  disabled={isLoading}
                  onClick={() => submitQuestionsToServer()}
                >
                  {isLoading ? "PLEASE WAIT..." : "SUBMIT QUESTIONS"}
                </Button>
              </Header>
              {questions.map((question, index) => {
                return (
                  <QuestionContainer
                    key={`question-options-${question.options.length}-${index}`}
                  >
                    <Box flexDirection="row" justifyContent="space-between">
                      <Box
                        alignSelf="flex-end"
                        flexDirection="row"
                        display="inline"
                      >
                        <IconButton
                          color="primary"
                          onClick={() => editQuestion(index)}
                        >
                          <Edit />
                        </IconButton>

                        <IconButton
                          color="error"
                          onClick={() =>
                            setSelectedQuestionIndexToDelete(index + 1)
                          }
                        >
                          <Delete />
                        </IconButton>
                      </Box>

                      <Box display="inline" sx={{ float: "right" }}>
                        <b>
                          Score:{" "}
                          <span style={{ fontWeight: "normal" }}>
                            {question.score}
                          </span>
                        </b>
                      </Box>
                    </Box>
                    <ReactQuill
                      value={question.description}
                      readOnly
                      className="readonly-quill"
                      style={{ marginBottom: 8 }}
                    />
                    {question.options.map((option) => (
                      <Box flexDirection="row" sx={{ marginBottom: 2 }}>
                        <input
                          type="checkbox"
                          disabled
                          checked={option.is_answer}
                        />
                        <span>{option.description}</span>
                      </Box>
                    ))}
                  </QuestionContainer>
                );
              })}
            </Card>
          )}
        </Grid>
      </Grid>

      <DeleteModal
        title="Are you sure?"
        prompt="You want to remove this question from the list of added questions?"
        onCancel={() => setSelectedQuestionIndexToDelete(undefined)}
        open={Number(selectedQuestionIndexToDelete) > 0}
        onConfirm={async () => {
          deleteQuestion(Number(selectedQuestionIndexToDelete) - 1);
          setSelectedQuestionIndexToDelete(undefined);
        }}
      />
    </div>
  );
};

/**
 * -------------------
 * STYLED COMPONENTS
 * -------------------
 */

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px dashed #efefee;
`;

const QuestionContainer = styled.div`
  background-color: #f9f9f9;
  border-radius: 5px;
  border: 1px solid #dfdfdf;
  margin: 8px;
  padding: 12px;
`;

export default AddQuestions;
