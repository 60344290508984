import React, { useMemo } from "react";
import TextField from "components/Form/TextField";
import { Box, MenuItem, Select } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { IStudent } from "types/students";
import { useStudentGroups } from "hooks/students";
import PageLayout from "components/PageLayout";
import Button from "components/button/Button";

export type IFormStudent = Partial<IStudent> & {
  password: string;
  name: string;
  email: string;
};

interface IAddOrEditStudentFormProps {
  initialStudent?: Partial<IStudent>;
  inEditMode?: boolean;
  onSubmit: (student: IFormStudent) => Promise<void>;
}

const AddOrEditStudentForm = ({
  initialStudent,
  onSubmit,
  inEditMode,
}: IAddOrEditStudentFormProps) => {
  const validator = useMemo(() => {
    const fieldValidators: any = {
      name: Yup.string()
        .required("Enter student name")
        .min(3, "Name must be more than two characters"),
      email: Yup.string()
        .required("Enter a valid email")
        .email("Enter a valid email"),
      gender: Yup.string().required("Select gender"),
      address: Yup.string(),
      group_id: Yup.number().required("Select a valid group"),
    };

    if (!inEditMode) {
      fieldValidators.password = Yup.string()
        .required("Enter a password for student account")
        .min(4, "Enter more than three characters");
    }
    return Yup.object().shape(fieldValidators);
  }, [inEditMode]);

  const {
    isLoading,
    isFetching,
    error,
    data: groups,
    refetch,
  } = useStudentGroups();

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={error}
      onRetry={() => refetch()}
    >
      <Formik
        initialValues={{
          name: initialStudent?.account?.name,
          email: initialStudent?.account?.email,
          gender: initialStudent?.gender,
          address: initialStudent?.address,
          group_id: initialStudent?.group_id,
          password: "",
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit({
            email: values.email!,
            name: values.name!,
            gender: values.gender,
            address: values.address,
            password: values.password,
            group_id: values.group_id,
          });
          setSubmitting(false);
        }}
        validationSchema={validator}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form
            style={{ textAlign: "center", marginTop: 24, marginBottom: 24 }}
          >
            <TextField
              type="text"
              name="name"
              error={!!(errors.name && touched.name)}
              helperText={errors.name}
              onChange={handleChange}
              onBlur={handleBlur}
              required
              value={values.name || ""}
              label="Student's Name"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            {!inEditMode && (
              <TextField
                type="email"
                name="email"
                error={!!(errors.email && touched.email)}
                helperText={errors.email}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.email || ""}
                label="Student's Email Address"
                variant="outlined"
                fullWidth
                style={{ marginBottom: 24 }}
              />
            )}

            <TextField
              name="gender"
              error={!!(errors.gender && touched.gender)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.gender}
              label="Student's Gender"
              style={{ marginBottom: 24, textAlign: "left" }}
              select
              defaultValue={initialStudent?.gender}
              fullWidth
            >
              <MenuItem key="male" value="Male">
                Male
              </MenuItem>
              <MenuItem key="female" value="Female">
                Female
              </MenuItem>
              <MenuItem key="other" value="Other">
                Other
              </MenuItem>
            </TextField>

            <TextField
              type="text"
              name="address"
              error={!!(errors.address && touched.address)}
              helperText={errors.address}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address || ""}
              label="Student's Address"
              variant="outlined"
              fullWidth
              style={{ marginBottom: 24 }}
            />

            <TextField
              name="group_id"
              error={!!(errors.group_id && touched.group_id)}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={errors.group_id}
              label="Student's Level"
              style={{ marginBottom: 24, textAlign: "left" }}
              select
              defaultValue={initialStudent?.group_id}
              fullWidth
            >
              {(groups?.data || []).map((group) => {
                return (
                  <MenuItem key={group.id} value={group.id}>
                    {group.name?.toUpperCase()}
                  </MenuItem>
                );
              })}
            </TextField>

            {!inEditMode && (
              <TextField
                type="password"
                name="password"
                error={!!(errors.password && touched.password)}
                helperText={errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                value={values.password || ""}
                label="Student's Password"
                variant="outlined"
                fullWidth
              />
            )}
            <Box mt={4} display="flex" justifyContent="center">
              <Button type="submit" variant="contained" loading={isSubmitting}>
                SUBMIT
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </PageLayout>
  );
};

export default AddOrEditStudentForm;
