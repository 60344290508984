import { Box } from "@mui/system";
import ConfirmationModal from "components/ConfirmationModal";
import Countdown from "components/Countdown";
import PageLayout from "components/PageLayout";
import Button from "components/button/Button";
import { white } from "config/colors";
import { dashboardHomeUrl } from "config/urls";
import { useAddAttempt, useSubmitAttempt } from "hooks/assessments";
import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";

export type SubmissionItem = {
  question_id: number;
  selected_option_id: number;
};

const AttemptAssessmentView = () => {
  const assessmentId = useParams<{ id: string }>().id;
  const { isLoading, mutate, data: attemptDetails, error } = useAddAttempt();
  const { mutateAsync } = useSubmitAttempt();
  const [selectedAnswers, setSelectedAnswers] = useState<SubmissionItem[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    mutate(Number(assessmentId));
  }, [assessmentId]);

  const retry = () => mutate(Number(assessmentId));

  const updateSelectedAnswer = (
    questionId: number,
    selectedOptionId: number
  ) => {
    const previous = [...selectedAnswers];

    const index = previous.findIndex(
      (value) => value.question_id === questionId
    );
    if (index > -1) {
      previous[index].selected_option_id = selectedOptionId;
    } else {
      previous.push({
        question_id: questionId,
        selected_option_id: selectedOptionId,
      });
    }

    setSelectedAnswers(previous);
  };

  const submitAnswers = async () => {
    try {
      await mutateAsync({ assessmentId, answers: selectedAnswers });
      toast("Assessment submitted successfully!", { type: "success" });
      setHasSubmitted(true);
      setTimeout(() => {
        history.replace(dashboardHomeUrl());
      }, 1500);
    } catch (error) {
      // toast an error.
      toast(
        "Error while submitting assessment. Please contact admin for help resetting your timer",
        { type: "error", autoClose: 60 * 1000 }
      );
      history.replace(dashboardHomeUrl());
    } finally {
      setShowConfirmationModal(false);
    }
  };

  return (
    <PageLayout loading={isLoading} error={error} onRetry={() => retry()}>
      <Prompt
        when={!hasSubmitted}
        message="You have un-saved changes. Are you sure you want to leave?"
      />
      <div style={{ background: white, paddingTop: 16 }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            margin: 16,
            marginTop: 0,
            marginBottom: 0,
          }}
        >
          <Countdown
            onEllapsed={() => submitAnswers()}
            durationInSec={(attemptDetails?.data?.duration || 1) * 60}
          />
        </div>

        {attemptDetails?.data?.questions?.map((question) => {
          return (
              <QuestionContainer style={{ marginTop: -16 }}>
                <ReactQuill
                  value={question.description}
                  readOnly
                  className="readonly-quill quiz"
                  style={{ marginBottom: 8 }}
                />
                {question.options.map((option) => (
                  <Box
                    key={option.id}
                    flexDirection="row"
                    sx={{ marginBottom: 2 }}
                  >
                    <input
                      type="radio"
                      name={`question-${question.id}`}
                      value={option.id}
                      onChange={() => {
                        updateSelectedAnswer(question.id, option.id);
                      }}
                    />
                    <span
                      dangerouslySetInnerHTML={{ __html: option.description }}
                    ></span>
                  </Box>
                ))}
              </QuestionContainer>
          );
        })}
      </div>

      <Button
        variant="contained"
        loading={hasSubmitted}
        style={{ marginTop: 32, minWidth: 150 }}
        onClick={() => setShowConfirmationModal(true)}
      >
        SUBMIT
      </Button>

      <ConfirmationModal
        open={showConfirmationModal}
        title="Are you sure"
        prompt="You want to submit this assessment?"
        onCancel={() => setShowConfirmationModal(false)}
        onConfirm={() => submitAnswers()}
      />
    </PageLayout>
  );
};

const QuestionContainer = styled.div`
  border-bottom: 2px dashed #ccc;
  padding: 16px;
`;

export default AttemptAssessmentView;
