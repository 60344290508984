import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Home,
  BookmarkAdd,
  Logout,
  PeopleAltOutlined,
  BookOutlined,
  ListAltOutlined,
} from "@mui/icons-material";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  viewAssessmentsUrl,
  viewCategoriesUrl,
  viewStudentGroupsUrl,
  viewStudentsUrl,
} from "config/urls";
import { clearAuthCookies } from "api/cookie";
import { white } from "config/colors";
import { IAccount, IAccountRole } from "types/auth";
import React from "react";

/** Renders the dashboard sidebar drawer menu */
const Sidebar = ({
  onItemClicked,
  account,
}: {
  onItemClicked?: Function;
  account: IAccount;
}) => {
  return (
    <div>
      <InfoWrapper>
        <Avatar src="/default.png" />
        <Name>{account.name?.toUpperCase()}</Name>
      </InfoWrapper>

      <Divider />

      {account.role === IAccountRole.STUDENT ? (
        <List>
          <SidebarLink
            onClick={() => onItemClicked && onItemClicked()}
            to="/dashboard/home"
            activeClassName="activeLink"
          >
            <ListItem button key="Home">
              <StyledListItemIcon>
                <Home />
              </StyledListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </SidebarLink>
        </List>
      ) : (
        <React.Fragment>
          <List>
            <SidebarLink
              onClick={() => onItemClicked && onItemClicked()}
              to="/dashboard/home"
              activeClassName="activeLink"
            >
              <ListItem button key="Home">
                <StyledListItemIcon>
                  <Home />
                </StyledListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </SidebarLink>

            <SidebarLink
              onClick={() => onItemClicked && onItemClicked()}
              to={viewCategoriesUrl()}
              activeClassName="activeLink"
            >
              <ListItem button key="Subjects">
                <StyledListItemIcon>
                  <BookmarkAdd />
                </StyledListItemIcon>
                <ListItemText primary="Subjects" />
              </ListItem>
            </SidebarLink>

            <SidebarLink
              onClick={() => onItemClicked && onItemClicked()}
              to={viewStudentGroupsUrl()}
              activeClassName="activeLink"
            >
              <ListItem button key="Levels">
                <StyledListItemIcon>
                  <ListAltOutlined />
                </StyledListItemIcon>
                <ListItemText primary="Levels" />
              </ListItem>
            </SidebarLink>

            <SidebarLink
              onClick={() => onItemClicked && onItemClicked()}
              to={viewStudentsUrl()}
              activeClassName="activeLink"
            >
              <ListItem button key="Students">
                <StyledListItemIcon>
                  <PeopleAltOutlined />
                </StyledListItemIcon>
                <ListItemText primary="Students" />
              </ListItem>
            </SidebarLink>

            <SidebarLink
              onClick={() => onItemClicked && onItemClicked()}
              to={viewAssessmentsUrl()}
              activeClassName="activeLink"
            >
              <ListItem button key="Assessments">
                <StyledListItemIcon>
                  <BookOutlined />
                </StyledListItemIcon>
                <ListItemText primary="Assessments" />
              </ListItem>
            </SidebarLink>
          </List>
        </React.Fragment>
      )}
      <Divider />
      <List>
        <ListItem
          button
          key="Logout"
          onClick={() => {
            clearAuthCookies();
            window.location.href = "/";
          }}
        >
          <StyledListItemIcon>
            <Logout />
          </StyledListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </div>
  );
};

const StyledListItemIcon = styled(ListItemIcon)`
  & {
    color: #fff !important;
  }
`;

const SidebarLink = styled(NavLink)`
  & {
    text-decoration: none !important;
    color: #fff;
    display: block;
  }
`;

const Name = styled.p`
  color: ${white};
  margin-left: 16px;
  font-weight: 500;
`;

const InfoWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px dashed ${white};
  padding: 4px;
`;

export default Sidebar;
