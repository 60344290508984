import { Card, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import AssessmentForm from "components/Assessments/AssessementForm";
import PageLayout from "components/PageLayout";
import { viewAssessmentsUrl } from "config/urls";
import {
  useAssessmentDetails,
  useUpdateAssessment,
} from "hooks/assessments";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Assessment } from "types/assessments";

const EditAssessment = () => {
  const history = useHistory();
  const assessmentId = useParams<{ id: string }>().id;

  const { mutateAsync: submitAssessment } = useUpdateAssessment();

  const { isLoading, isFetching, data, error } =
    useAssessmentDetails(assessmentId);

  const submitAssessmentToServer = async (assessment: Partial<Assessment>) => {
    try {
      await submitAssessment({ id: assessmentId, assessment });
      toast("Assessement updated successfully", { type: "success" });
      history.replace(viewAssessmentsUrl());
    } catch (error) {
      toast(
        (error as AxiosError)?.response?.data?.message ||
          "Couldn't update assessment",
        { type: "error" }
      );
    }
  };

  return (
    <PageLayout loading={isLoading || isFetching} error={error}>
      <Grid container spacing={4}>
        <Grid item xs={12} md={12} lg={7}>
          <Card sx={{ borderRadius: 3 }}>
            <Header>
              <Typography variant="subtitle1" fontWeight="bold">
                EDIT ASSESSMENT
              </Typography>
            </Header>
            <div style={{ padding: 16 }}>
              <AssessmentForm
                onSubmit={submitAssessmentToServer}
                initialAssessment={data?.data}
              />
            </div>
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

/**
 * -------------------
 * STYLED COMPONENTS
 * -------------------
 */

const Header = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 2px dashed #efefee;
`;

export default EditAssessment;
