import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { ButtonProps, MenuItem } from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledMenu = styled((props: any) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface IDropdownProps {
  items: { label: string; icon?: React.ReactNode; onClick?: () => any }[];
  style?: React.CSSProperties;
  containerStyle: React.CSSProperties;
  title?: string;
  buttonProps?: ButtonProps;
}

function Dropdown({ items, style, title, buttonProps, containerStyle }: IDropdownProps) {
  const [dropdownId] = React.useState(
    `dropdown-menu-${new Date().getTime()}${Math.random()
      .toString()
      .substring(2, 8)}`
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div  style={containerStyle}>
      <Button
        id={dropdownId}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        style={style}
        {...buttonProps || {}}
      >
        {title || "Options"}
      </Button>
      <StyledMenu
        id={dropdownId}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {(items || []).map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              handleClose();
              item.onClick && item.onClick();
            }}
          >
            {item.icon && item.icon}
            {item.label}
          </MenuItem>
        ))}
      </StyledMenu>
    </div>
  );
}

export default Dropdown;
