export const viewCategoriesUrl = () => "/dashboard/categories";
export const viewCategoryDetailsUrl = (categoryId: number | string) => `/dashboard/categories/${categoryId}`;
export const viewQuestionsUrl = (categoryId: number | string) => `/dashboard/categories/${categoryId}`;
export const addQuestionsUrl = (categoryId: number | string)=> `/dashboard/categories/${categoryId}/questions`
export const editQuestionUrl = (questionId: number | string)=> `/dashboard/categories/questions/${questionId}/edit`
export const viewStudentsUrl = () => "/dashboard/students";
export const viewStudentGroupsUrl = () => "/dashboard/groups";
export const addAssessmentsUrl = () => "/dashboard/assessments/add";
export const viewAssessmentsUrl = () => "/dashboard/assessments";
export const viewAssessmentDetailsUrl = (id: string | number) => `/dashboard/assessments/${id}/details`;
export const viewEditAssessmentUrl = (id: string | number) => `/dashboard/assessments/${id}/edit`;
export const attemptAssessmentUrl = (assessmentId: string | number) => `/dashboard/attempt/${assessmentId}`;
export const dashboardHomeUrl = () => "/dashboard/home";
